import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { FaEnvelopeOpen, FaWhatsapp } from "react-icons/fa";

const ContactPage = () => {
  return (
    <Layout>
      <main>
        <div className="container pt-24 md:pt-36">
          <h1 className="text-3xl font-extralight uppercase mb-5">Contact</h1>

          <ul className="font-extralight text-xl md:text-3xl space-y-2">
            <li className="flex items-center">
              <span className="text-xl md:text-2xl mr-4">
                <FaWhatsapp />
              </span>
              <a
                href="https://api.whatsapp.com/send?phone=6282261193390&text=Halo%20saya%20tertarik%20tentang%20JEWEL%20Residences"
                target="_blank"
                className="hover:underline hover:underline-offset-4"
              >
                +62 822 6119 3390
              </a>
            </li>
            <li className="flex items-center">
              <span className="text-xl md:text-2xl mr-4">
                <FaEnvelopeOpen />
              </span>
              <a
                href="mailto:contact@jewelresidences.com"
                className="hover:underline hover:underline-offset-4"
              >
                contact@jewelresidences.com
              </a>
            </li>
          </ul>
        </div>
      </main>
    </Layout>
  );
};

export default ContactPage;

export const Head = () => (
  <>
    <title>JEWEL Residences @ Serpong, Tangerang Selatan - Location</title>
    <meta
      name="description"
      content="Our contact: contact@jewelresidences.com"
    />
    <meta name="og:url" content="https://www.jewelresidences.com/contact/" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;700;800&display=swap"
      rel="stylesheet"
    ></link>
    <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-K3DM7CP"
    ></script>
  </>
);
